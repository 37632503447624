.react-contextmenu {
  z-index: 10;
  background-color: rgb(60, 60, 60);
  min-width: 100px;
  box-shadow: rgb(0 0 0) 0px 2px 4px;
  padding: 6.5px 0;
}
.react-contextmenu .react-contextmenu-item {
  font-size: 13px;
  padding: 0 12px;
  line-height: 24px;
  cursor: pointer;
  color: rgb(240, 240, 240);
}
.react-contextmenu .react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: rgb(9, 71, 113);
}
